import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'todayDate'
})
export class TodayDatePipe implements PipeTransform {
  transform(value: Date): string {
    const today = new Date();
    const inputDate = new Date(value);

    if (
      inputDate.getDate() === today.getDate() &&
      inputDate.getMonth() === today.getMonth() &&
      inputDate.getFullYear() === today.getFullYear()
    ) {
      // If the date matches today's date, return the time in HH:mm format
      return inputDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    } else {
      // If the date doesn't match today's date, return the full date in the default format
      return inputDate.toLocaleDateString();
    }
  }
}