import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  configUrl = 'assets/config.json';
  usersUrl = 'assets/users.json';

  constructor(private _httpClient: HttpClient) { }

  getConfig() {
    return this._httpClient.get<any>(this.configUrl);
  }

  getDummyUsers() {
    return this._httpClient.get<any>(this.usersUrl);
  }

  getEmployee() {
    const href =
      environment.AUTH_API_URL +
      'mssql/employees/' +
      localStorage.getItem('agencycode');

    return this._httpClient.get<any>(href, {
      headers: {
        // accept: '*/*',
        'Content-Type': 'application/json',
        Authorization:
          'Bearer ' + localStorage.getItem('userAccessToken') || '',
      },
    });
  }

  getEmployeeShift(startDate: string, endDate: string, EmployeeID: string) {
    const href =
      environment.AUTH_API_URL +
      'mssql/employee/shift?startDate=' +
      startDate +
      '&endDate=' +
      endDate +
      '&EmployeeID=' +
      EmployeeID +
      '&dbName=' +
      localStorage.getItem('agencycode');

    return this._httpClient.get<any>(href, {
      headers: {
        // accept: '*/*',
        'Content-Type': 'application/json',
        Authorization:
          'Bearer ' + localStorage.getItem('userAccessToken') || '',
      },
    });
  }

  getEmployeeReplacement(
    workHeaderId: number,
    WorkLoadId: null,
    Distance: number = 50
  ) {
    const href =
      environment.AUTH_API_URL +
      'mssql/employee/replacement?workHeaderId=' +
      workHeaderId +
      '&WorkLoadId=' +
      WorkLoadId +
      '&Distance=' +
      Distance +
      '&dbName=' +
      localStorage.getItem('agencycode');

    return this._httpClient.get<any>(href, {
      headers: {
        // accept: '*/*',
        'Content-Type': 'application/json',
        Authorization:
          'Bearer ' + localStorage.getItem('userAccessToken') || '',
      },
    });
  }

  Login(data: any): Observable<any> {
    const href = environment.AUTH_API_URL + 'aws-auth/login';

    return this._httpClient.post<any>(href, data, {
      headers: {
        // accept: '*/*',
        'Content-Type': 'application/json',
      },
    });
  }

  SignUp(data: any): Observable<any> {
    const href = environment.AUTH_API_URL + 'aws-auth/register';

    return this._httpClient.post<any>(href, data, {
      headers: {
        // accept: '*/*',
        'Content-Type': 'application/json',
      },
    });
  }

  VerifyCognito(data: any): Observable<any> {
    const href = environment.AUTH_API_URL + 'aws-auth/verifyCognito';

    return this._httpClient.post<any>(href, data, {
      headers: {
        // accept: '*/*',
        'Content-Type': 'application/json',
      },
    });
  }

  onboardingAgency(data: any): Observable<any> {
    const href =
      environment.AUTH_API_URL + 'aws-auth/onboard/' + data.agencycode;

    return this._httpClient.post<any>(href, data, {
      headers: {
        // accept: '*/*',
        'Content-Type': 'application/json',
      },
    });
  }

  getProfile(data: any): Observable<any> {
    const href =
      environment.AUTH_API_URL + 'aws-auth/getProfile';

    return this._httpClient.post<any>(href, data, {
      headers: {
        // accept: '*/*',
        'Content-Type': 'application/json',
      },
    });
  }

  getProfileEmail(data: any): Observable<any> {
    const href =
      environment.AUTH_API_URL + 'aws-auth/getProfileEmail';

    return this._httpClient.post<any>(href, data, {
      headers: {
        // accept: '*/*',
        'Content-Type': 'application/json',
      },
    });
  }

  ConfirmSignUp(data: any): Observable<any> {
    const href = environment.AUTH_API_URL + 'aws-auth/confirmSignup';

    return this._httpClient.post<any>(href, data, {
      headers: {
        // accept: '*/*',
        'Content-Type': 'application/json',
      },
    });
  }

  resendCode(data: any): Observable<any> {
    const href = environment.AUTH_API_URL + 'aws-auth/resendCode';

    return this._httpClient.post<any>(href, data, {
      headers: {
        // accept: '*/*',
        'Content-Type': 'application/json',
      },
    });
  }

  forgotPassword(data: any): Observable<any> {
    const href = environment.AUTH_API_URL + 'aws-auth/forgotPassword';

    return this._httpClient.post<any>(href, data, {
      headers: {
        // accept: '*/*',
        'Content-Type': 'application/json',
      },
    });
  }

  confirmPassword(data: any): Observable<any> {
    const href = environment.AUTH_API_URL + 'aws-auth/confirmPassword';

    return this._httpClient.post<any>(href, data, {
      headers: {
        // accept: '*/*',
        'Content-Type': 'application/json',
      },
    });
  }

  renewToken(data: any) {
    const href = environment.AUTH_API_URL + 'aws-auth/renewToken';

    return this._httpClient.post<any>(href, data, {
      headers: {
        // accept: '*/*',
        'Content-Type': 'application/json',
      },
    });
  }

  magicLogin(data: any) {
    const href = environment.AUTH_API_URL + 'aws-auth/magic/login';

    return this._httpClient.post<any>(href, data, {
      headers: {
        // accept: '*/*',
        'Content-Type': 'application/json',
      },
    });
  }

  updateAttributes(data: any) {
    const href = environment.AUTH_API_URL + 'aws-auth/update';

    return this._httpClient.put<any>(href, data, {
      headers: {
        // accept: '*/*',
        'Content-Type': 'application/json',
      },
    });
  }

  updatePushToken(data: any) {
    const href = environment.AUTH_API_URL + 'aws-auth/push/token';

    return this._httpClient.put<any>(href, data, {
      headers: {
        // accept: '*/*',
        'Content-Type': 'application/json',
      },
    });
  }

  getUsers(search?: string) {
    const loginUserRole = localStorage.getItem('Role');
    const agencycode = localStorage.getItem('agencycode');
    let href =
      environment.AUTH_API_URL +
      'user-realm?Realm=Sembracare&page=' +
      0 +
      '&limit=' +
      10 + '&Role=' + loginUserRole + '&agencycode=' + agencycode;

    // if (localStorage.getItem('agencycode') !== "SembraCare")
    //   href = href + '&agencycode=' + localStorage.getItem('agencycode');

    // if (localStorage.getItem('UserRole') == "USER" || localStorage.getItem('UserRole') == "CAREGIVER")
    //   href = href + '&Role=' + localStorage.getItem('UserRole');

    if (search) 
      href = href + '&s=' + search;

    return this._httpClient.get<any>(href, {
      headers: {
        // accept: '*/*',
        'Content-Type': 'application/json',
        Authorization:
          'Bearer ' + localStorage.getItem('userAccessToken') || '',
      },
    });
  }

  deleteUserRealm(data: any) {
    const href = environment.AUTH_API_URL + 'user-realm/deleteRealm';

    return this._httpClient.post<any>(href, data, {
      headers: {
        // accept: '*/*',
        'Content-Type': 'application/json',
        Authorization:
          'Bearer ' + localStorage.getItem('userAccessToken') || '',
      },
    });
  }

  updateProfilePic(data: any) {
    const href = environment.AUTH_API_URL + 'user/profile/picture';
    return this._httpClient.put<any>(href, data, {
      headers: {
        // accept: '*/*',
        'Content-Type': 'application/json',
        Authorization:
          'Bearer ' + localStorage.getItem('userAccessToken') || '',
      },
    });
  }

  sendPushNotification(data: any) {
    const href = environment.AUTH_API_URL + 'user/sendNotification';
    return this._httpClient.post<any>(href, data, {
      headers: {
        // accept: '*/*',
        'Content-Type': 'application/json',
        Authorization:
          'Bearer ' + localStorage.getItem('userAccessToken') || '',
      },
    });
  }

  getUnreadCounts(toUserId: string) {
    const requestUrl =
      environment.apiURL + 'chat/unreadCount/?toUserId=' + toUserId;
    return this._httpClient.get<any[]>(requestUrl, {
      headers: this.getFormDataHeadersWithSession(
        localStorage.getItem('userAccessToken')
      ),
    });
  }

  getConnections(loginuser: string, search?: string) {
    let requestUrl = environment.apiURL + 'connections?fromUserId=' + loginuser; // /?page=' + 0 + '&limit=' + 100;
    if(search) {
      requestUrl = requestUrl + '&s=' + search;
    }
    return this._httpClient.get<any[]>(requestUrl, {
      headers: this.getFormDataHeadersWithSession(
        localStorage.getItem('userAccessToken')
      ),
    });
  }

  getUploadedFile(fileName: string) {
    const requestUrl = environment.AUTH_API_URL + 'fileupload/' + fileName;
    return this._httpClient.get(requestUrl, {
      headers: this.getFormDataHeadersWithSession(
        localStorage.getItem('userAccessToken')
      ),
      reportProgress: true,
      observe: 'events',
      responseType: 'blob',
    });
  }

  upload(file: File): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    formData.append('files', file);

    const req = new HttpRequest(
      'POST',
      `${environment.AUTH_API_URL}fileupload`,
      formData,
      {
        reportProgress: true,
        responseType: 'json',
        headers: this.getFormDataHeadersWithSession(
          localStorage.getItem('userAccessToken')
        ),
      }
    );

    return this._httpClient.request(req);
  }

  // employe shift data

  getShifts(search?: string, shiftIds?: string[]) {
    let href = environment.AUTH_API_URL + 'shifts?page=' + 0 + '&limit=' + 100;

    if (search) {
      href = href + '&s=' + search;
    }

    if (shiftIds) {
      for (let index = 0; index < shiftIds.length; index++) {
        const element = shiftIds[index];
        href = href + '&shiftIds=' + element;
      }
    }

    return this._httpClient.get<any>(href, {
      headers: {
        // accept: '*/*',
        'Content-Type': 'application/json',
        Authorization:
          'Bearer ' + localStorage.getItem('userAccessToken') || '',
      },
    });
  }

  getShiftPost(search?: string, shiftIds?: string[]) {
    let href = environment.AUTH_API_URL + 'shifts/search';

    const body = {
      page: 0,
      limit: 100,
    } as any;

    if (search) {
      body['s'] = search;
    }

    if (shiftIds) {
      body['shiftIds'] = shiftIds;
    }

    return this._httpClient.post<any>(href, body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization:
          'Bearer ' + localStorage.getItem('userAccessToken') || '',
      },
    });
  }

  addShift(body: any) {
    const href = environment.AUTH_API_URL + 'shifts';

    return this._httpClient.post<any>(href, body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization:
          'Bearer ' + localStorage.getItem('userAccessToken') || '',
      },
    });
  }

  updateShiftNotes(body: any) {
    const href = environment.AUTH_API_URL + 'shifts/updateNotes';

    return this._httpClient.post<any>(href, body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization:
          'Bearer ' + localStorage.getItem('userAccessToken') || '',
      },
    });
  }

  addUserShift(data: any): Observable<any> {
    const href = environment.AUTH_API_URL + 'user-shifts/update/mutiple';

    return this._httpClient.post<any>(href, data, {
      headers: {
        // accept: '*/*',
        'Content-Type': 'application/json',
        Authorization:
          'Bearer ' + localStorage.getItem('userAccessToken') || '',
      },
    });
  }

  deleteAllShift(): Observable<any> {
    const href = environment.AUTH_API_URL + 'user-shifts/all/data';

    return this._httpClient.delete<any>(href, {
      headers: {
        // accept: '*/*',
        'Content-Type': 'application/json',
        Authorization:
          'Bearer ' + localStorage.getItem('userAccessToken') || '',
      },
    });
  }

  adminApproveShift(data: any) {
    const href = environment.AUTH_API_URL + 'user-shifts/admin/approve';

    return this._httpClient.post<any>(href, data, {
      headers: {
        // accept: '*/*',
        'Content-Type': 'application/json',
        Authorization:
          'Bearer ' + localStorage.getItem('userAccessToken') || '',
      },
    });
  }

  acceptRejectShift(data: any) {
    const href = environment.AUTH_API_URL + 'user-shifts/shiftUpdate';

    return this._httpClient.post<any>(href, data, {
      headers: {
        // accept: '*/*',
        'Content-Type': 'application/json',
        Authorization:
          'Bearer ' + localStorage.getItem('userAccessToken') || '',
      },
    });
  }

  getShiftUsers(shiftId: string, search?: string) {
    let href =
      environment.AUTH_API_URL +
      'user-shifts?shiftId=' +
      shiftId +
      '&page=' +
      0 +
      '&limit=' +
      10;

    if (search) {
      href = href + '&s=' + search;
    }

    return this._httpClient.get<any>(href, {
      headers: {
        // accept: '*/*',
        'Content-Type': 'application/json',
        Authorization:
          'Bearer ' + localStorage.getItem('userAccessToken') || '',
      },
    });
  }

  public getFormDataHeadersWithSession(session: string | null) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', '*');
    headers = headers.set('Authorization', 'Bearer ' + session);
    return headers;
  }
}
