import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpService } from '../utilities/services/http.service';
import { TranslateService } from '@ngx-translate/core';
import { AgencyRoleType } from '../utilities/enums/agencyrole.enum';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  constructor(
    private router: Router,
    private _snackBar: MatSnackBar,
    private httpService: HttpService,
    public translate: TranslateService
  ) {}
  logOut() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  gotoOnboarding() {
    this.router.navigate(['/onboard/hchprod']);
  }

  isUserAuthenticated() {
    const token = localStorage.getItem('userAccessToken');
    if (token) {
      return true;
    }
    return false;
  }

  ShowMenuCards() {
    const loginUserRealmRole = localStorage.getItem('Role');
    const loginUserRole = localStorage.getItem('UserRole');
    if (loginUserRole === AgencyRoleType.ADMIN) {
      // system user will access all functionality of the app
      return true;
    } else {
      if (
        loginUserRealmRole === AgencyRoleType.ADMIN ||
        loginUserRealmRole === AgencyRoleType.OWNER
      ) {
        // as per rule the above roles can chat with everyone
        return true;
      } else {
        return false;
      }
    }
  }

  goTo(link: string) {
    this.router.navigate([`/${link}`]);
  }
}
