import { MaterialModule } from './material.module';

import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SocketIoModule } from 'ngx-socket-io';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';
import { TranslateModule } from '@ngx-translate/core';
import { AutocompleteForceSelectionDirective } from '../directives/autocomplete-force-selection.directive';
import { DateAgoPipe } from '../pipes/date-ago.pipe';
import { TodayDatePipe } from '../pipes/messages-date';

const sharedModule = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  HttpClientModule,
  MaterialModule,
  SocketIoModule,
  NgxMaterialTimepickerModule,
  NgxMatDatetimePickerModule,
  NgxMatTimepickerModule,
  NgxMatNativeDateModule,
  TranslateModule,
];

const sharedComponent = [DateAgoPipe, TodayDatePipe];

const directives = [AutocompleteForceSelectionDirective];

@NgModule({
  imports: [...sharedModule],
  exports: [...sharedModule, ...sharedComponent],
  declarations: [...directives, ...sharedComponent],
  // entryComponents: [
  // ...directives,
  //   // ...sharedComponent
  // ]
})
export class SharedModule {}
