<mat-toolbar class="header">
  <!-- <span class="example-spacer"></span> -->
  <img
    class="logo"
    src="../../assets/images/MySembraTeal.svg"
    alt="SembraCare Logo"
  />
  <span class="example-spacer"></span>

  <div *ngIf="isUserAuthenticated(); else publicNav">
    <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>menu</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="gotoOnboarding()" *ngIf="ShowMenuCards()">
        <span>{{ "HOME.ONBOARDING" | translate }}</span>
      </button>

      <button mat-menu-item (click)="goTo('profile')">
        <span>{{ "HOME.PROFILE" | translate }}</span>
      </button>

      <button mat-menu-item (click)="logOut()">
        <span>{{ "HOME.LOGOUT" | translate }}</span>
      </button>
    </mat-menu>
  </div>

  <ng-template #publicNav>
    <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>menu</mat-icon>
    </button>
    <mat-menu #menu="matMenu" #publicNav>
      <button mat-menu-item (click)="goTo('login')">
        <span>{{ "LOGIN.TITLE" | translate }}</span>
      </button>

      <button mat-menu-item (click)="goTo('resend-code')">
        <span>{{ "LOGIN.VERIFY_ACCOUNT" | translate }}</span>
      </button>

      <button mat-menu-item (click)="goTo('signup')">
        <span>{{ "SIGNUP.TITLE" | translate }}</span>
      </button>
    </mat-menu>
  </ng-template>
</mat-toolbar>
